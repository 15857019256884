import './link.scss';
import { useParams } from 'react-router-dom';
import { useState } from 'react'
import { getNested } from 'components/shared/componentUtils';
import useComponents from 'components/hooks/Components/useComponents';
import Icon from '../Icon/Icon';

const Link = ({ type, link, configs, rowData, children, action }) => {
    const { id } = useParams(); // needed: can be referenced in in item.type === 'var';
    const { navTo } = useComponents();
    const [ loading ] = useState(false);

    const onLinkClick = (event) => {
        event.preventDefault();
        if (configs?.onClick) {
            configs.onClick(id, rowData);
        } else {
            navTo(route);
        }
    }

    const getRoute = (data, rowData) => {    
        let route = '';
        data.forEach(item => {
            if (item.type === 'text') {
                route += `/${item.val}`;
            } else if (item.type === 'var') {
                route += `/${eval(item.val)}`;
            } else if (item.type === 'prop') {
                route += `/${getNested(item.val, rowData)}`;
            }
        });
        return route;
    };
    const route = link ? link : !configs?.onClick ? getRoute(configs.data, rowData) : null;

    return (
        <div className={`link-container`}>
            {loading ? <div className='link-spinner spinner'/>
            :<a  href={route} 
                className={`link-target`} 
                onClick={(event) =>{onLinkClick(event)}}
            >{type === 'icon' ? <Icon icon={children}/> :children}</a>}
        </div>
    )
}

export default Link;
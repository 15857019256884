import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { locationGameroomLayout as layout } from 'pages/Locations/LocationReports/LocationGameroom/LocationGameroomLayout';
import { deepCopy, getDefaultDateRange, getAPIDates } from 'components/shared/componentUtils';
import { getFinanceData, formatGameNames } from 'shared/utils';
import { text as t } from 'shared/text';
import * as e from 'shared/excel/excel';
import useProfile from 'hooks/Profile/useProfile';
import useTables from 'components/Tables/TableParts/TableHooks/useTables';
import useUtils from 'hooks/useUtils';
import Button from 'components/Button/Button';
import Card from 'app-components/Cards/Card/Card';
import CashDisplay from 'components/CashDisplay/CashDisplay';
import DateRangePicker from 'components/DateRangePicker/DateRangePicker';
import FinanceDisplay from 'components/FinanceDisplay/FinanceDisplay';
import TableListed from 'components/Tables/TableListed';

const LocationKiosk = () => {
    const { id } = useParams();
    const { updateLocationBreadcrumbs, updateLocationHeader } = useProfile();
    const { tableData, tableTotals, 
            getTableColumns, updateTable } = useTables();
    const { callAPI, callAPIGet } = useUtils();
    const [ pageData, setPageData ] = useState();
    const [ countryCode, setCountryCode ] = useState();
    const [ tableRecords, setTableRecords ] = useState();
    const [ masked, setMasked ] = useState([true]);
    const [ currentRange, setCurrentRange ] = useState();
    const [ financials, setFinancials ] = useState();
    const [ cashBreakdown, setCashBreakdown ] = useState();
    const [ defaultDates, setDefaultDates ] = useState();
    const runOnce = useRef();
    const deviceTypes = useRef();
    const tableId = 'location-kiosk-' + id;

    useEffect(() => {
        if (runOnce.current) { return }
        runOnce.current = true;
        layout.customFormats = { gameTypeName: formatGameNames};
        callAPI('locationDetails', locationDetailsCallback, {id: id});
    }, []);

    const locationDetailsCallback = (data) => {
        const details = data?.locationDetails;
        callAPI('companyDetails', companyDetailsCallback, {id: details?.companyId});
        deviceTypes.current = data?.kioskTypes
        setPageData(details);
        updateLocationHeader(details);
        updateLocationBreadcrumbs(details);
        const range = getDefaultDateRange(tableId);
        setDefaultDates(range);
        setCurrentRange(range)
        getReportData(range);
    }

    const companyDetailsCallback = (data) => {
        setCountryCode(data?.companyDetailsData?.companyInfo?.country);
    }

    const getReportData = (range) => {
        setMasked([true]);
        tableId && updateTable('dates', tableId, range);
        const params = getAPIDates(range);
        params.locationId = id;
        callAPIGet('report-LocationGameroom', gameroomReportCallback, params);
    }

    const gameroomReportCallback = (data) => {
        const records = data?.data ? data.data : [];
        setTableRecords(records);
        setFinancials(getFinanceData(deviceTypes.current, data?.totalFinancialSummary, records));
        setCashBreakdown(data?.totalCashBreakdown || null);
        setMasked([false]);
    }

    const onDateRangePick = (range) => {
        getReportData(range);
        setCurrentRange(range);
    }

    const onExportToExcel = () => {
        const dateInfo = {
            type: 'table',
            columns : ['locationId', 'locationName', 'startDate', 'endDate'],
            headers: {
                locationId: t.locationId,
                locationName: t.locationName,
                startDate: t.startDate,
                endDate: t.endDate,
            },
            data:[{
                locationId: id,
                locationName: pageData.locationName,
                startDate: e.getExcelDate(currentRange[0]),
                endDate: e.getExcelDate(currentRange[1])
            }],
            configs: {
                formats: {locationName: 'text-wrap'}
            }
        };

        const financeInfo = e.getExcelFinancials(financials);

        const cashInfo = cashBreakdown ? e.getExcelCash(cashBreakdown) : null;

        const tableRowData = deepCopy(tableData[tableId]);
        if (layout.subtotals){
            tableRowData.push({totals: tableTotals[tableId]});
        }
        const columns = getTableColumns(tableId, layout);
        const tableInfo = e.getExcelTable(t.gameDetails, columns, tableRowData, layout);
        const sizes = columns.map(key => layout.sizes[key]);
        const infoArray = cashBreakdown ? [dateInfo, financeInfo, cashInfo, tableInfo] : [dateInfo, financeInfo, tableInfo]
        e.exportToFile(id + ' Gameroom Performance Report', infoArray, sizes);
    }

    return (<div className='full grid'>
        <Card hideLabel={true} classes='full'>
            <DateRangePicker
                classes='quarter'
                defaultVal={defaultDates}
                label={t.dateRange}
                callback={onDateRangePick}
            />
            <Button 
                classes='green sixth match-labeled-input'
                disabled={masked?.[0]}
                onClick={onExportToExcel}
            >{t.exportToExcel}</Button>
        </Card>
        <Card label={t.financials} classes='third' masked={masked?.[0]}> 
            <FinanceDisplay types={deviceTypes.current} classes='full' data={financials?.data} hideTitle={true} tooltip={true}/>
        </Card>
        {cashBreakdown && <Card label={t.cashBreakdown} classes='third' masked={masked?.[0]}>
            <CashDisplay classes='full' data={cashBreakdown} countryCode={countryCode}/>
        </Card>} 
        <div className='table-title full'>{t.gameDetails}</div>
        <TableListed
            id={tableId}
            classes='full'
            data={tableRecords}
            layout={layout}
            masked={masked?.[0]}
        />
    </div>)
}

export default LocationKiosk;

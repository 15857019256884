import './deviceGames.scss';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { deviceGamesLayout as layout, deviceGamesBetLayout as betLayout } from './DeviceGamesLayout';
import { deepCopy, getDefaultDateRange, getAPIDates } from 'components/shared/componentUtils';
import { getFinanceData, formatGameNames } from 'shared/utils';
import { text as t } from 'shared/text';
import * as e from 'shared/excel/excel';
import useProfile from 'hooks/Profile/useProfile';
import useTables from 'components/Tables/TableParts/TableHooks/useTables';
import useUtils from 'hooks/useUtils';
import Button from 'components/Button/Button';
import Card from 'app-components/Cards/Card/Card';
import CashDisplay from 'components/CashDisplay/CashDisplay';
import DateRangePicker from 'components/DateRangePicker/DateRangePicker';
import FinanceDisplay from 'components/FinanceDisplay/FinanceDisplay';
import TableCheckbox from 'components/Tables/TableParts/TableCheckbox/TableCheckbox';
import TableListed from 'components/Tables/TableListed';

const DeviceGames = () => {
    const { id } = useParams();
    const { setHeader, updateDeviceBreadcrumbs } = useProfile();
    const { tableData, tableTotals, tableToggles,
            getTableColumns, updateTable } = useTables();
    const { callAPI, callAPIGet } = useUtils();
    const [ pageData, setPageData ] = useState();
    const [ countryCode, setCountryCode ] = useState();
    const [ tableRecords, setTableRecords ] = useState();
    const [ currentRange, setCurrentRange ] = useState();
    const [ cashBreakdown, setCashBreakdown ] = useState();
    const [ defaultDates, setDefaultDates ] = useState();
    const [ currentLayout, setCurrentLayout ] = useState(layout);
    const [ masked, setMasked ] = useState([true]);
    const [ rawFinanceData, setRawFinanceData ] = useState();
    const [ financials, setFinancials ] = useState();
    const [ maskedFinance, setMaskedFinance ] = useState([true]);
    const [ maskedCash, setMaskedCash ] = useState([true]);
    const runOnce = useRef();
    const deviceType = useRef();
    const toggleTimeout = useRef();
    const tableId = 'device-games-' + id;
    const toggleProp = 'betLevel';

    useEffect(() => {
        if (runOnce.current) {return};
        runOnce.current = true;
        layout.customFormats = { gameTypeName: formatGameNames};
        setCurrentLayout(layout)
        const range = getDefaultDateRange(tableId);
        setDefaultDates(range);
        setCurrentRange(range);
        callAPIGet('deviceDetails', deviceDetailsCallback, {terminalId: id});
    }, []);

    useEffect(() => {
        const showBetRates = tableToggles?.[tableId]?.includes(toggleProp) ? true : false;
        getReportData(currentRange || getDefaultDateRange(tableId), showBetRates);
        clearTimeout(toggleTimeout.current);
        toggleTimeout.current = setTimeout(()=> {
            const newLayout = showBetRates ? betLayout : layout;
            newLayout.customFormats = { gameTypeName: formatGameNames};
            setCurrentLayout(newLayout)
        }, 300);
    }, [tableToggles?.[tableId]]);

    useEffect(()=> {
        if (!rawFinanceData || !tableRecords) {return};
        const fin = getFinanceData([deviceType.current], rawFinanceData, tableRecords);
        setFinancials(fin);
        setMaskedFinance([false])
    }, [rawFinanceData, tableRecords])

    const deviceDetailsCallback = (data) => {
        callAPI('companyDetails', companyDetailsCallback, {id: data?.companyId});
        setPageData(data);
        updateDeviceBreadcrumbs(data);
        const type = data.terminalType;
        deviceType.current = type.id;
        setHeader(type.name + ': ' + id);
    }

    const companyDetailsCallback = (data) => {
        setCountryCode(data?.companyDetailsData?.companyInfo?.country);
    }

    const getReportData = (range, showBetRates) => {
        setMasked([true]);
        setMaskedFinance([true]);
        setMaskedCash([true]);
        tableId && updateTable('dates', tableId, range);
        const params = getAPIDates(range);
        params.terminalId = id;
        callAPIGet('deviceFinancials', deviceFinancialsCallback, params);
        callAPIGet('deviceCashBreakdown', deviceCashBreakdownCallback, params);
        params.betLevel = showBetRates;
        callAPIGet('report-DeviceGames', deviceGamesCallback, params);
    }

    const deviceFinancialsCallback = (data) => {
        setRawFinanceData(data);
    }

    const deviceCashBreakdownCallback = (data) => {
        setMaskedCash([false]);
        setCashBreakdown(data?.cashBreakdown || []);
    }

    const deviceGamesCallback = (data) => {
        setMasked([false]);
        setTableRecords(data?.data || []);
    }

    const onDateRangePick = (range) => {
        maskAll();
        getReportData(range, tableToggles?.[tableId]?.includes(toggleProp));
        setCurrentRange(range);
    }

    const maskAll = () => {
        setMasked([true]);
        setMaskedFinance([true]);
        setMaskedCash([true]);
    }

    const onExportToExcel = () => {
        const dateInfo = {
            type: 'table',
            columns: ['deviceId', 'startDate', 'endDate', 'locationName'],
            headers: {
                deviceId: t.deviceId,
                startDate: t.startDate,
                endDate: t.endDate,
                locationName: t.locationName,
            },
            data:[{
                deviceId: id,
                startDate: e.getExcelDate(currentRange[0]),
                endDate: e.getExcelDate(currentRange[1]),
                locationName: pageData?.location?.label
            }],
            configs: {
                formats: {locationName: 'text-wrap'}
            }
        };
        const financeInfo = e.getExcelFinancials(financials);

        const cashInfo = e.getExcelCash(cashBreakdown);
        
        const tableRowData = deepCopy(tableData[tableId]);
        if (currentLayout.subtotals){
            tableRowData.push({totals: tableTotals[tableId]});
        }
        const columns = getTableColumns(tableId, currentLayout);
        const tableInfo = e.getExcelTable(t.gameDetails, columns, tableRowData, currentLayout);
        const sizes = columns.map(key => currentLayout.sizes[key]);
        e.exportToFile(id + ' Game Performance Report', [dateInfo, financeInfo, cashInfo, tableInfo], sizes);
    }

    return (<div className='full grid'>
        <Card hideLabel={true} classes='full'>
            <DateRangePicker
                classes='quarter'
                defaultVal={defaultDates}
                label={t.dateRange}
                disabled={masked?.[0]}
                callback={onDateRangePick}
            />
            <Button
                classes='green sixth match-labeled-input'
                disabled={masked?.[0]}
                onClick={onExportToExcel}
            >{t.exportToExcel}</Button>
        </Card>
        <Card label={t.financials} classes='third margin-top' masked={maskedFinance?.[0]}>
            <FinanceDisplay types={[deviceType.current]} classes='full' data={financials?.data} hideTitle={true}/>
        </Card>
        <Card label={t.cashBreakdown} classes='third margin-top' masked={maskedCash?.[0]}>
            <CashDisplay classes='full' data={cashBreakdown} countryCode={countryCode}/>
        </Card>
        <div className='table-title full margin-top'>{t.gameDetails}</div>
        <TableListed
            id={tableId}
            classes='full'
            data={tableRecords}
            layout={currentLayout}
            masked={masked?.[0]}
            searchBarContent={<>
                <TableCheckbox
                    id={toggleProp}
                    tableId={tableId}
                    tableState='toggles'
                    classes='table-searchbar sixth'
                    prop={toggleProp}
                    label={t.showBetRates}
                    masked={masked?.[0]}
                />
            </>}
        />
    </div>)
}

export default DeviceGames;

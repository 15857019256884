import './locationDetails.scss';
import { useParams } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { locationDetailsLayout as layout } from './LocationDetailsLayout';
import { getAPIDates, getDefaultDateRange, updateURL } from 'components/shared/componentUtils'
import { getFinanceData } from 'shared/utils';
import { text as t } from 'shared/text';
import useAuth from 'hooks/Auth/useAuth';
import useInputs from 'components/hooks/Inputs/useInputs';
import useModal from 'components/hooks/Modal/useModal';
import useProfile from 'hooks/Profile/useProfile';
import useUtils from 'hooks/useUtils';
import Button from 'components/Button/Button';
import Card from 'app-components/Cards/Card/Card';
import CashDisplay from 'components/CashDisplay/CashDisplay';
import DateRangePicker from 'components/DateRangePicker/DateRangePicker';
import Dropdown from 'components/Dropdown/Dropdown';
import FinanceDisplay from 'components/FinanceDisplay/FinanceDisplay';
import Input from 'components/Input/Input';
import Page from 'app-components/Page/Page';

const LocationDetails = ({newLocation, newSubmit, newCancel}) => {
    const { id }  = useParams();
    const { getAuth } = useAuth();
    const { inputValues, changeInputErrors, 
            validateForm, updateNestedInputData, 
            reNestedData } = useInputs();
    const { removeModal } = useModal();
    const { locationTabs, updateLocationBreadcrumbs, updateLocationHeader } = useProfile();
    const { callAPI, callAPIGet, 
            callAPIPatch,updateNotice, 
            hasRank, updateLocationTabs } = useUtils();
    const [ pageData, setPageData ] = useState();
    const [ countryCode, setCountryCode ] = useState();
    const [ states, setStates ] = useState();
    const [ companies, setCompanies ] = useState();
    const [ statuses, setStatuses ] = useState();
    const [ types, setTypes ] = useState();
    const [ users, setUsers ] = useState();
    const [ defaultDates, setDefaultDates ] = useState();
    const [ financials, setFinancials ] = useState();
    const [ cashBreakdown, setCashBreakdown ] = useState();
    const [ masked, setMasked ] = useState([true]);
    const [ disabled, setDisabled ] = useState(false);
    const [ isPOS, setIsPOS ] = useState(false);
    const runOnce = useRef(false);
    const setOnce = useRef(false);
    const hasAlerts = useRef(false);
    const deviceTypes = useRef();
    const permitted = hasRank('locationManager');
    const urlId = 'location-details-' + id;

    useEffect(() => {
        if (runOnce.current) {return};
        runOnce.current = true;
        callAPI('statesGet', statesGetCallback);
        callAPI('companiesByUser', companiesByUserCallback);
        if (newLocation) {
            callAPIGet('locationNew', locationDetailsCallback)
        } else {
            callAPI('locationDetails', locationDetailsCallback, {id: id});
        }
    }, []);

    useEffect(()=> {
        if (pageData && users && companies && statuses && types && states && financials &&  !setOnce.current) {
            setOnce.current = true;
            const dropdownList = {
                locationType: {list: 0},
                status: {list: 1, nullable: statuses[0].description, labelProp: 'description'},
                salesAgentId: {list: 2},
                primaryUserId: {list: 2},
                companyId: {list: 3},
                ['address-state']: {list: 4, labelProp: 'stateCode'}
            }
            if (newLocation) {
                pageData.id = -1;
                pageData.companyId = companies[0].id;
            }
            const update = updateNestedInputData(pageData, dropdownList, [types, statuses, users, companies, states]);
            update && setMasked([false]);
        }
    }, [pageData, types, statuses, users, companies, states, financials])

    useEffect(() => {
        changeInputErrors(null);
    }, [inputValues])

    const statesGetCallback = (data) => {
        setStates(data?.states ? data.states : [])
    }

    const companiesByUserCallback = (data) => {
        setCompanies(data?.tableData ? data.tableData : []);
    }

    const locationDetailsCallback = (data) => {
        const details = data?.locationDetails;
        callAPI('companyDetails', companyDetailsCallback, {id: details?.companyId});
        const params = { companyId:  details?.companyId || -1 }
        if (details) {
            const types = data?.kioskTypes;
            deviceTypes.current = types;
            !newLocation && updateLocationTabs(types);
            hasAlerts.current = (deviceTypes.current?.includes(2) || deviceTypes.current?.includes(7)) ? true : false;
            const hasPOS = deviceTypes.current?.includes(4);
            setIsPOS(hasPOS);
            setPageData(details);
            updateLocationBreadcrumbs(details);
            if (newLocation) {
                setFinancials([]);
                setCashBreakdown(null);
            } else {
                updateLocationHeader(details);
                hasPOS ? getPOSData() : setFinancials([]);
            }
        } else {
            setPageData({})
        }
        callAPI('users', usersCallback, hasRank('owner') ? {} : {companyId: getAuth().companyId});
        callAPI('locationStatuses', locationStatusesCallback);
        callAPI('locationTypes', locationTypesCallback, params);
    }

    const companyDetailsCallback = (data) => {
        setCountryCode(data?.companyDetailsData?.companyInfo?.country);
    }

    const getPOSData = () => {
        const range = getDefaultDateRange(urlId);
        updateURL(urlId, 'dates', range);
        setDefaultDates(range);
        getReportData(range);
    }

    const getReportData = (range) => {
        setMasked([true]);
        const params = getAPIDates(range);
        params.locationId = id;
        callAPIGet('report-LocationGameroom', gameroomReportCallback, params);
    }

    const gameroomReportCallback = (data) => {
        if (!data?.isSuccessful) {
            setMasked([false])
            return;
        }
        setFinancials(getFinanceData(deviceTypes.current, data?.totalFinancialSummary, data.data));
        setCashBreakdown(data?.totalCashBreakdown || null);
        setMasked([false]);
    }

    const locationStatusesCallback = (data) => {
        setStatuses(data?.tableData ? data.tableData : []);
    }

    const locationTypesCallback = (data) => {
        setTypes(data?.locationTypeList ? data.locationTypeList : []);
    }

    const usersCallback = (data) => {
        setUsers(data?.webPortalUserList ? data.webPortalUserList : []);
    }

    const onDateRangePick = (range) => {
        updateURL(urlId, 'dates', range);
        getReportData(range);
    }

    const onSubmit = (event, setLoading) => {
        const validators = layout.validators;
        const errorsList = validateForm(validators);
        const props = {event: event, setLoading: setLoading}
        if (errorsList) {
            updateNotice('formErrors', props);
            changeInputErrors(errorsList);
        } else {
            setLoading(true);
            setDisabled(true);
            changeInputErrors({});
            const params = { locationDetails: reNestedData(pageData)}
            params.locationDetails.address.state = params.locationDetails.address.state.toString();
            if (newLocation) {
                params.locationDetails.id = null;
                callAPI('locationCreate', locationUpdateCallback, params, props);
            } else {
                callAPIPatch('locationUpdate', locationUpdateCallback, params, props);
            }
        }
    }


    const locationUpdateCallback = (data, props) => {
        props.setLoading(false);
        setDisabled(false);
        if (newLocation && data.isSuccessful) {
            updateNotice(data);
            newSubmit(data);
            removeModal();
        } else {
            updateNotice(data, props);
        }
    }

    const onCancel = () => {
        newCancel();
    }

    return (
        <Page
            subPage={newLocation ? '' : t.details}
            tabs={newLocation ? [] : locationTabs.tabs}
            contentClasses='grid location-details'
        >
            <Card hideLabel={true} classes='full margin-top' >
                <Input  label={t.locationName} id='locationName' classes='half'
                        masked={masked?.[0]} noPermission={!permitted}/>
                <Dropdown label={t.locationType} line='locationType' classes='quarter' data={types}
                        masked={masked?.[0]}  noPermission={!permitted}/>
                <Dropdown label={t.status} id='status' classes='quarter' data={statuses}
                        masked={masked?.[0]} noPermission={!permitted}/>
                <Dropdown label={t.salesAgent} id='salesAgentId' classes='quarter' data={users}
                        masked={masked?.[0]} noPermission={!permitted}/>
                <Dropdown label={t.assignedUser} id='primaryUserId' classes='quarter' data={users}
                        masked={masked?.[0]} noPermission={!permitted}/>
                <Input  label={t.locationId} line='id' classes='quarter display-only'
                        masked={masked?.[0]} noPermission={!permitted}/>
                <Dropdown id='companyId' classes='quarter' data={companies} masked={masked?.[0]}
                    label={t.currentCompany} placeholder={t.selectOne} noPermission={!permitted}/>
            </Card>
            <Card label={t.address} classes='full'>
                <Input  label={t.address} section='address' line='streetName1' classes='half'
                        type='allCaps' masked={masked?.[0]} noPermission={!permitted} />
                <Input  label={t.addressTwo} section='address' line='streetName2' classes='half'
                        type='allCaps' masked={masked?.[0]} noPermission={!permitted} />
                <Input  label={t.city} section='address' line='city' classes='third'
                        type='allCaps' masked={masked?.[0]} noPermission={!permitted}/>
                <Dropdown label={t.state} section='address' line='state' classes= 'sixth' data= {states}
                        labelProp='stateCode' masked={masked?.[0]} noPermission={!permitted}/>
                <Input  label={t.zip} section='address' line='zip' classes='sixth'
                        type='zip' masked={masked?.[0]} noPermission={!permitted}/>
                <Input  label={t.county} section='address' line='county' classes='sixth'
                        type='allCaps' masked={masked?.[0]} noPermission={!permitted}/>
                <Input  label={t.territory} section='address' line='territory' classes='sixth'
                        type='allCaps' masked={masked?.[0]} noPermission={!permitted}/>
            </Card>
            <Card label={t.primaryContact} classes='half'>
                <Input  label={t.contactName} section='primaryContact' line='name' classes='half'
                        masked={masked?.[0]} noPermission={!permitted}/>
                <Input  label={t.title} section='primaryContact' line='title' classes='half'
                        masked={masked?.[0]} noPermission={!permitted}/>
                <Input  label={t.phoneNumber} section='primaryContact' line='phoneNumber' classes='half'
                        type='phone' masked={masked?.[0]} noPermission={!permitted}/>
                <Input  label={t.email} section='primaryContact' line='email' classes='half'
                        masked={masked?.[0]} noPermission={!permitted}/>
            </Card>
            <Card label={t.secondaryContact} classes='half'>
                <Input  label={t.contactName} section='secondaryContact' line='name' classes='half'
                        masked={masked?.[0]} noPermission={!permitted}/>
                <Input  label={t.title} section='secondaryContact' line='title' classes='half'
                        masked={masked?.[0]} noPermission={!permitted}/>
                <Input  label={t.phoneNumber} section='secondaryContact' line='phoneNumber' classes='half'
                        type='phone' masked={masked?.[0]} noPermission={!permitted}/>
                <Input  label={t.email} section='secondaryContact' line='email' classes='half'
                        masked={masked?.[0]} noPermission={!permitted}/>
            </Card>
            {isPOS && <Card label={'Device Financials'} classes={`${cashBreakdown ? 'full' : 'half'}`} masked={masked?.[0]}>
                <DateRangePicker
                    classes='full'
                    defaultVal={defaultDates}
                    label={t.dateRange}
                    disabled={masked?.[0]}
                    onClick={onDateRangePick}
              />
                <FinanceDisplay types={deviceTypes.current} classes={`${cashBreakdown ? 'half' : 'full'}`} data={financials?.data} tooltip={true}/>
                {cashBreakdown && <CashDisplay title={t.cashBreakdown} data={cashBreakdown} countryCode={countryCode} classes='half'/>}
            </Card>}
            {newLocation && <div className='full modal-buttons-container grid'>
                <Button
                    classes='red quarter'
                    disabled={disabled}
                    onClick={onCancel}
                    >{t.cancel}
                </Button>
                <Button
                    classes='green quarter last'
                    type='submit'
                    onClick={(event, setLoading)=>onSubmit(event, setLoading)}
                    >{t.submit}
                </Button>
            </div>}
            {!newLocation && permitted && <Button 
                classes={`green ${isPOS && !cashBreakdown ? 'half' : 'quarter'} last `}
                type='submit'
                onClick={(event, setLoading)=>onSubmit(event, setLoading)}
                >{t.submit}
            </Button>}
        </Page>
    )
}

export default LocationDetails;

import './companyUsers.scss';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { companyUsersLayout as layout } from './CompanyUsersLayout';
import { companyTabsList as tabs } from 'shared/tabs';
import { text as t } from 'shared/text';
import useModal from 'components/hooks/Modal/useModal';
import useProfile from 'hooks/Profile/useProfile';
import useUtils from 'hooks/useUtils';
import Page from 'app-components/Page/Page';
import Table from 'app-components/Table/Table/Table';

const CompanyUsers = () => {
    const { modalTrigger, setModalTrigger } = useModal();
    const { id } = useParams();
    const { setHeader } = useProfile();
    const { callAPI } = useUtils();
    const [ companyUsers, setCompanyUsers ] = useState();
    const [ tableLoading, setTableLoading ] = useState([true]);
    const runOnce = useRef(false);

    useEffect(() => {
        if (runOnce.current) return;
        runOnce.current = true;
        const params = {companyId: id};
        callAPI('companyUsers', companyUsersCallback, params);
        callAPI('companyDetails', detailsCallback, {id: id})
    }, [])

    useEffect(()=> {
        if (modalTrigger === 'mask-table') {
            const params = {companyId: id};
            callAPI('companyUsers', companyUsersCallback, params);
            setTableLoading([true]);
            setModalTrigger('');
        }
    }, [modalTrigger])

    const companyUsersCallback = (data) => {
        setCompanyUsers(data?.tableData ? data.tableData : []);
        setTableLoading([false]);
    }

    const detailsCallback = (data) => {
        const name = data?.companyDetailsData?.companyInfo?.companyName;
        setHeader(name ? name : '');
    }

    return (
        <Page 
            subPage={t.users} 
            tabs={tabs}
        >
            <Table 
                data={companyUsers}
                layout={layout}
                context={useProfile}
                loading={tableLoading}

            />
        </Page>
    )
}

export default CompanyUsers;